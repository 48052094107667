.session-edit-presenter-dropdown {

}

.session-edit-presenter-dropdown:hover {

}

.session-edit-presenter-dropdown:active  {

}

.session-edit-presenter-dropdown:disabled {

}

.textAreaSessionEdit:focus{
    border-color: #ed1c24;
    box-shadow: rgba(190, 45, 45, 0.52) 0 0 4px 4px;
    /*background-color: #00000033;*/
}

.session-input-field:focus{
    border-color: #ed1c24;
    box-shadow: rgba(190, 45, 45, 0.52) 0 0 4px 4px;
}

.form-check-input:checked{
    background-color: #ed1c24;
    border-color: #ed1c24;
}

.form-check-input:focus{
    border-color: #ed1c24;
    box-shadow: rgba(190, 45, 45, 0.52) 0 0 4px 4px;
}