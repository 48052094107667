.login-form-header {
    background-color: #BE2D2D;
    color: white;
}

.login-form-submit-button {

}

.login-form-submit-button:hover {

}

.login-form-submit-button:active  {

}

.login-form-submit-button:disabled {

}