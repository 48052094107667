.button-standard {
    color: white;
    background-color: #dc3545;
    border-color: #dc3545;
}

.button-standard:hover {
    color: white;
    background-color: #bb2d3b;
    border-color: #bb2d3b;
}

.button-standard:active  {
    color: white !important;
    background-color: #a22e3c !important;
    border-color: #a22e3c !important;
}

.button-standard:disabled {
    color: white !important;
    background-color: #e87c86 !important;
    border-color: #e87c86 !important;
}

.card-header-standard {
    background-color: #BE2D2D;
    color: white;
}

.progress-bar-standard * {
    background-color: #dc3545;
}

/* Firefox */

.slider-standard::-moz-range-thumb {
    background-color: #dc3545 !important;
}

.slider-standard:focus::-moz-range-thumb {
    background-color: #a22e3c !important;
    box-shadow: none;
}

.slider-standard:active::-moz-range-thumb {
    background-color: #dc3545 !important;
    box-shadow: rgba(237, 28, 36, 0.52) 0 0 4px 4px !important;
}


/* Chromium */

.slider-standard::-webkit-slider-thumb {
    background-color: #dc3545 !important;
}

.slider-standard:focus::-webkit-slider-thumb {
    background-color: #dc3545 !important;
    box-shadow: none;
}

.slider-standard:active::-webkit-slider-thumb {
    background: #dc3545 !important;
    box-shadow: rgba(237, 28, 36, 0.52) 0 0 4px 4px !important;
}