/*input[type="range"]::-webkit-slider-thumb {*/
/*    background: #df4759 !important;*/
/*    border: 0;*/
/*}*/

/*input[type="range"]:focus::-webkit-slider-thumb {*/
/*    background: #df4759 !important;*/
/*    box-shadow: rgba(237, 28, 36, 0.52) 0 0 4px 4px !important;*/
/*    border: 0;*/
/*}*/

.textAreaSessionEdit{
    /*border:solid 2px #0000008C;
    background-color:#00000033;*/
    padding:0.5%;
    border-radius:5px;
    height:7em;
}
