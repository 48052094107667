.review-comment {
    background-color: #00000033;
    border: solid 2px #0000008C;
}

.comment-created {
    color: gray
}

.comment-separator {
    border-top: #00000033 solid 2px;
}

.edit-comment-button {
    background-color: #f8f9fa;
}

.edit-comment-button:hover {
    background-color: #d3d4d5;
}

.comment-save-button {

}

.comment-save-button:hover {

}

.comment-save-button:active  {

}

.comment-save-button:disabled {

}

.comment-post-button {

}

.comment-post-button:hover {

}

.comment-post-button:active  {

}

.comment-post-button:disabled {

}