.session-reviews-header {

}

.session-reviews-header-button {
    color: black;
    background-color: white;
}

.session-reviews-header-button:hover {
    color: black;
    background-color: #dedede;
}

.session-reviews-header-button:active {
    color: black !important;
    background-color: #ababab !important;
    border-color: #ababab !important;
}

.review-open-close-button {

}

.review-open-close-button:hover {

}

.review-open-close-button:active  {

}

.review-open-close-button:disabled {

}

.session-review-container {
    border-color: #ed1c24;
}

.session-review-like-container, .session-review-improve-container {
    border: solid 2px #0000008C;
    background-color: #00000033;
}

.session-review-created-text, .session-review-modified-text {
    color: gray;
}

.session-review-create-form-like-container, .session-review-create-form-improve-container {
    border:solid 2px #0000008C;
    background-color:#dddddd;
}

.session-review-create-form-like-container:focus, .session-review-create-form-improve-container:focus {
    border-color: #ed1c24;
    box-shadow: rgba(237, 28, 36, 0.52) 0 0 4px 4px;
    background-color: #dddddd;
}

.textAreaReview {
    border: solid 2px #0000008C;
    background-color: #dddddd;
    padding: 0.5%;
    border-radius: 5px;
    height: 7em;
}

.textAreaReview:active {
    background-color: #dddddd;
}

.textAreaReview:focus {
    border-color: #ed1c24;
    box-shadow: rgba(237, 28, 36, 0.52) 0 0 4px 4px;
    background-color: #dddddd;
}

.error1010 {
    color: #ed1c24;
    font-weight: bold;
}


/* Firefox */

.session-review-create-form-slider::-moz-range-thumb {

}

.session-review-create-form-slider:focus::-moz-range-thumb {

}

.session-review-create-form-slider:active::-moz-range-thumb {

}


/* Chromium */

.session-review-create-form-slider::-webkit-slider-thumb {

}

.session-review-create-form-slider:focus::-webkit-slider-thumb {

}

.session-review-create-form-slider:active::-webkit-slider-thumb {

}