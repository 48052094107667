.forgot-password-form {
    background-color: #BE2D2D;
    color: white;
}

.forgot-password-form-submit-button {

}

.forgot-password-form-submit-button:hover {

}

.forgot-password-form-submit-button:active  {

}

.forgot-password-form-submit-button:disabled  {

}