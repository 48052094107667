.session-submission-info {
    border: 2px solid red
}

.create-session-form-card {

}

.xp-factor-info-button {

}

.xp-factor-info-button:hover {

}

.xp-factor-info-button:active  {

}

.xp-factor-info-text {
    color: white;
    background-color: rgba(255, 100, 100, 0.85);
}

.xp-factor-slider {
    border: 0;
    color: red;
}

.create-session-second-presenter-button {

}

.create-session-second-presenter-button:hover {

}

.create-session-second-presenter-button:active {

}

.create-session-second-presenter-button:disabled {

}