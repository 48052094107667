.navMenu {
    border-bottom: 3px solid #ed1c24;
}

.navItem {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
}

.navItemNotifications {
    font-size: 1.1em;
    text-transform: uppercase;
    font-weight: 500;
}
.nav-link:hover {
    color: #ed1c24;
}
.notifications-container {
    position: absolute; 
    top: 80px; 
    left: 75%; 
     min-width: 15%; 
    z-index: 100; 
}